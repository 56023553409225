<template>
  <d-select-option
    :options="options"
    v-model="model"
    class-name="background-light-blue-inputs"
    @on:select-option:change="$emit('on:select-option:change', $event)"
  />
</template>

<script>
export default {
  name: "Select",
  props: {
    options: {
      type: Object,
      default: null,
    },
    model: {
      type: Object,
      default: null,
    }
  },
  mounted() {
    console.log(this.model);
  }
}
</script>

<style lang="scss" scoped>
</style>
